import React, {useState} from "react";
import {Form, InputGroup} from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const Zoom = ({playerState, proxy, showHeader}) => {


    const maxZoom = 100;
    const changeCallback = (e) => {
        proxy("setZoom", e.target.value)
    }

    const incrementZoom = (val) => {
        let zv = playerState.currentZoom + val
        if (zv < 0) {
            zv = 0;
        }
        if (zv > maxZoom) {
            zv = maxZoom;
        }
        proxy("setZoom", zv);
    }


    return <div className={"border p-1"}>
        {showHeader && <div className={"d-flex flex-row align-items-center justify-content-center"}>
            <h5 className={"h-100 m-0"}>Zoom Control</h5>
            <Instructions/>
        </div>}
        <InputGroup>
            <InputGroup.Text className={"p-2 fs-6"} id="b">Zoom</InputGroup.Text>
            <Form.Control id={"zoomslider"}
                          type="number" value={playerState.currentZoom}
                          increment={25}
                          onChange={changeCallback}
                          className={"ml-1"}/>
            <InputGroup.Text className={"p-2"}>%</InputGroup.Text>
            <InputGroup.Text className={"p-2"}>
            </InputGroup.Text>
            <Form.Range
                id="zoom"
                type="range"
                min="0"
                max={maxZoom}
                value={playerState.currentZoom}
                onChange={changeCallback}
            />
        </InputGroup>
        <div className="d-flex align-content-center justify-content-center border mt-1">
            <ButtonGroup size={"sm"}>
                <Button variant={"secondary"} size={"sm"} onClick={() => {
                    incrementZoom(-25)
                }}>
                    <i className={"bi bi-dash"}></i>
                </Button>
                <Button variant={"secondary"} size={"sm"} onClick={() => {
                    incrementZoom(25)
                }}>
                    <i className={"bi bi-plus"}></i>
                </Button>
                <Button variant={"secondary"} size={"sm"}
                        onClick={() => {
                           proxy("setZoom", 0)
                        }}>
                    <i className="bi bi-house"></i>
                </Button>
                <Instructions/>
            </ButtonGroup>
        </div>
    </div>
};

const Instructions = (title, text) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant={"secondary"} size="sm" onClick={handleShow}>
                <i className="bi bi-question-circle"></i>
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Zoom</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Use the zoom slider to zoom in and out on the timeline.

                    </p>
                </Modal.Body>
            </Modal>
        </>
    );
}