import {ButtonGroup, ButtonToolbar, InputGroup} from "react-bootstrap";
import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Modal from "react-bootstrap/Modal";


export const LoopShed = ({playerState, proxy, showHeader}) => {
    // const [loopIterations, setLoopIterations] = useState(2)
    // const [loopMove, setLoopMove] = useState(false)
    // const [loopExpand, setLoopExpand] = useState(false)


    // const toggleLoopshed = () => {
    //     if (playerState.playing) {
    //         proxy("pause")
    //     } else {
    //         if(loopMove) {
    //             proxy("startAutoMove", loopIterations)
    //         } else if (loopExpand) {
    //             proxy("startAutoExpand", loopIterations)
    //         }
    //     }
    // }

    return <div className={"border p-1"}>
        <div className={''}>
            {showHeader && <div className={'d-flex flex-row align-items-center justify-content-center'}>
                <h5 className={"m-0"}>Loop Behavior</h5>
                <Instructions/>
            </div>}
            <InputGroup>
                <InputGroup.Text className={"p-2 fs-6 text-end"} id="btnGroupAddon">After:</InputGroup.Text>
                <Form.Control
                    variant={"outline-dark"}
                    type="number"
                    aria-describedby="btnGroupAddon"
                    value={playerState.loopRepeats}
                    className={"p-2"}
                    min={2}
                    onChange={(e) => proxy('setLoopRepeats', e.target.value)}
                />
                <InputGroup.Text className={"p-2 fs-6 text-end"} id="btnGroupAddon">repeats</InputGroup.Text>
            </InputGroup>
            <InputGroup>
                <InputGroup.Text className={"p-2 fs-6 text-end col-3"}
                                 id="btnGroupAddon">Expand:</InputGroup.Text>
                <InputGroup.Checkbox className={"p-1"} aria-label="Expand" checked={playerState.autoExpandLoop}
                                     onChange={
                                         (val) => {
                                             proxy('setAutoExpandLoop', val.target.checked)
                                         }}
                />
                <InputGroup.Text className={"p-2 fs-6 text-end flex-grow-1"}
                                 id="btnGroupAddon">Advance:</InputGroup.Text>
                <InputGroup.Checkbox className={"p-1"} aria-label="Move Loop" checked={playerState.autoMoveLoop}
                                     onChange={
                                         (val) => {
                                             proxy('setAutoMoveLoop', val.target.checked);
                                         }}
                />
            </InputGroup>
            <InputGroup>
                <InputGroup.Text className={"p-2 fs-6 col-3"} id="btnGroupAddon">Change Pitch:</InputGroup.Text>
                <InputGroup.Checkbox className={"p-1"} aria-label="Move Loop"
                                     checked={playerState.autoChangePitch}
                                     onChange={
                                         (val) => {
                                             proxy('setAutoChangePitch', val.target.checked);
                                         }}
                                     disabled={playerState.playerType === "youtubePlayer"}
                />
                <InputGroup.Text className={"p-2 fs-6 text-end"} id="btnGroupAddon">by:</InputGroup.Text>
                <Form.Control
                    variant={"outline-dark"}
                    type="number"
                    aria-describedby="btnGroupAddon"
                    value={playerState.autoPitchShift}
                    className={"p-2"}
                    min={-12}
                    max={12}
                    onChange={(e) => proxy('setAutoPitchShift', e.target.value)}
                />
                <InputGroup.Text className={"p-2 fs-6 text-end"} id="btnGroupAddon">semitones</InputGroup.Text>

            </InputGroup>
            <InputGroup>

                <InputGroup.Text className={"p-2 fs-6 col-3"} id="btnGroupAddon">Change Speed:</InputGroup.Text>
                <InputGroup.Checkbox className={"p-1"}
                                     aria-label="Change Speed:"
                                     checked={playerState.autoChangeSpeed}
                                     onChange={
                                         (val) => {
                                             proxy('setAutoChangeSpeed', val.target.checked);
                                         }}
                />

                <InputGroup.Text className={"p-2 fs-6 text-end"} id="btnGroupAddon">by:</InputGroup.Text>
                <Form.Control
                    variant={"outline-dark"}
                    type="number"
                    aria-describedby="btnGroupAddon"
                    value={Math.round(playerState.autoSpeedIncrease * 100)}
                    className={"p-2"}
                    min={10}
                    max={100}
                    disabled={playerState.playerType === "youtubePlayer"}
                    onChange={(e) => proxy('setAutoSpeedIncrease', e.target.value / 100)}
                />
                <InputGroup.Text className={"p-2 fs-6 text-end"} id="btnGroupAddon">%</InputGroup.Text>

            </InputGroup>
        </div>
    </div>

}

const Instructions = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant={"light"} size={"sm"} onClick={handleShow}>
                <i className="bi bi-question-circle"></i>
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Loop Behavior</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Expand After</h4>
                    <p>Toggle the expand checkbox to enable automatic loop expanding.</p>
                    <p>After the loop repeats the designated number of times the loop will expand by it's original
                        length.</p>
                    <h4>Advance After</h4>
                    <p>Toggle the advance checkbox to enable automatic loop advancing.</p>
                    <p>After the loop repeats the designated number of times the loop will advance by it's original
                        length.</p>
                    <h4>Change Speed</h4>
                    <p>Toggle the change speed checkbox to enable speed increases.</p>
                    <p>After the designated number of repeats, the loop will increase in speed.</p>
                    <h4>Change Pitch</h4>
                    <p>Toggle the change pitch checkbox to enable pitch increases.</p>
                    <p>After the designated number of repeats, the loop will increase in pitch.</p>
                    <p>Only works for uploaded audio, not the youtube player.</p>
                </Modal.Body>
            </Modal>
        </>
    );
}