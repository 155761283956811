import {Uploader} from "./Components/UploadMedia";

import LocalPlayer from "./Components/Player/LocalPlayer.tsx";
import {useState} from "react";

import {Navigation} from "./Components/Navigator";
import {Consent} from "./Components/Consent";

function App() {

    const [player, setPlayer] = useState(null)

    const [showLoadSong, setShowLoadSong] = useState(true)

    const [disableLoaderButton, setDisableLoaderButton] = useState(true)


    const loadLocalPlayer = (file, name) => {
        let p = <LocalPlayer mediaID={file} mediaName={name} playerType={"local"} key={"bust" + Math.random()}/>
        setPlayer(p)
        setShowLoadSong(false)
        setDisableLoaderButton(false)
    }

    const loadYoutubePlayer = (id) => {
        let p = <LocalPlayer mediaID={id} playerType={"youtube"} key={"bust" + Math.random()}/>
        setPlayer(p)
        setShowLoadSong(false)
        setDisableLoaderButton(false)
    }


    return (
        <div className="App">
            <Navigation
                loadMediaCallback={() => setShowLoadSong(!showLoadSong)}
                disableLoaderButton={disableLoaderButton}
            />
            {showLoadSong && <Uploader fileCallback={loadLocalPlayer} youtubeCallback={loadYoutubePlayer}
                                       closeCallback={() => setShowLoadSong(false)}/>}
            {player}

            <Consent/>

        </div>
    );
}

export default App;

