import {PitchShifter} from "soundtouchjs";
import Player from "./Player.ts"

class LoopShed {
    confIterations;
    confSemitones;
    currIterations = 0;
    currSemitones = 0;

    constructor(iterations, semitones) {
        this.confIterations = iterations;
        this.confSemitones = semitones;
    }
}
export class LocalPlayer extends Player {
    filter;
    loopShed = null;
    constructor(containerId, fileUrl, playerStateCallback) {
        super("localPlayer", playerStateCallback);
        super.initWaveform(containerId, fileUrl)
    }

    onWaveFormReady() {
        this.initFilter()
        super.onWaveFormReady();
    }

    onPlay() {
        this.syncFilter();
        this.connectFilter();
        super.onPlay();
    }

    onPause() {
        super.onPause();
        this.disconnectFilter()
    }

    initFilter() {
        let ctx = this.waveform.backend.getAudioContext()
        this.filter = new PitchShifter(ctx, this.waveform.backend.buffer, 1024);
        //wf.current.backend.setFilter(filter.current) @todo using setFilter might work and is cleaner
        //filter.current.connect(wf.current.backend.getAudioContext().destination)
        this.waveform.setVolume(0);
    }

    connectFilter() {
        this.filter.connect(this.waveform.backend.getAudioContext().destination)
    }

    disconnectFilter() {
        this.filter.disconnect()
    }

    syncFilter() {
        this.filter.percentagePlayed = this.waveform.backend.getPlayedPercents()
    }

    setSpeed(value) {
        let speed = value;
        this.waveform.setPlaybackRate(speed);
        this.playerState.setCurrentSpeed(speed);
        this.filter.tempo = speed;
    }

    setPitch(value) {
        this.filter.pitchSemitones = value;
        this.playerState.setCurrentPitch(value);
    }

    destroy() {
        this.disconnectFilter();
        this.filter.off();
        super.destroy();
    }


    onRegionOut(region) {
        if(this.playerState.autoChangeSpeed) {
            if(this.loopIterations >= this.playerState.loopRepeats ) {
                let newSpeed = this.playerState.currentSpeed + this.playerState.autoSpeedIncrease
                this.setSpeed(newSpeed);
                this.loopIterations = 0;
            }
        } else if(this.playerState.autoChangePitch) {
            if(this.loopIterations >= this.playerState.loopRepeats ) {
                let newPitch = this.playerState.currentPitch + Number(this.playerState.autoPitchShift)
                if(newPitch > 6) {
                    newPitch -= 12;
                }
                this.setPitch(newPitch);
                this.loopIterations = 0;
            }
        }
        return super.onRegionOut(region);
    }

}