import React, {useEffect, useRef, useState} from "react"
import {LocalPlayer as LP} from "../../lib/LocalPlayer.js";
import {YoutubePlayer as YTP} from "../../lib/YoutubePlayer.js";
import Player from "../../lib/Player.ts";
import PlayerState from "../../lib/PlayerState.ts";
// @ts-ignore
import {LoopControl} from "../ControlCluster/LoopControl.tsx";
import {Speed} from "../ControlCluster/SpeedControl.js";
import {PitchControl} from "../ControlCluster/PitchControl.js";
import {Zoom} from "../ControlCluster/ZoomControl.js";
import {LoopShed} from "../ControlCluster/LoopShed.js";
import {Playback} from "../ControlCluster/Playback.tsx";
import {Col, Container, Row} from "react-bootstrap";

function LocalPlayer({mediaID, playerType, mediaName}) {

    const rawPlayer = useRef(null);

    // used for youtube player
    const [playbackRates, setPlaybackRates] = useState([])

    const [playerState, setPlayerState] = useState(new PlayerState())

    const playerStateCallback = (playerState: PlayerState) => {
        let ps = PlayerState.fromPlayerState(playerState)
        setPlayerState(ps)
    }

    if (mediaName === undefined) {
        mediaName = mediaID
    }

    useEffect(() => {
        // if type is youtube, use the YoutubePlayer, otherwise use the LocalPlayer
        if (playerType === "youtube") {
            rawPlayer.current = new YTP("#waveform", mediaID, playerStateCallback)
        } else {
            rawPlayer.current = new LP("#waveform", mediaID, playerStateCallback)
        }
        return () => {
            rawPlayer.current.destroy()
        }
    }, [mediaID, playerType]);

    useEffect(() => {
        //handle and destroy for resizing
        const handleResize = () => {
            rawPlayer.current.redraw();
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const proxy = (func, ...args) => {
        //console.log("proxy", func, args)
        rawPlayer.current[func](...args)
    }

    return (
        <div>
            {playerType === "local" && <div>
                <div className="d-flex flex-column align-items-center">
                    <h3 className={"ml-5 mt-1 mb-1 d-lg-none"}>{mediaName}</h3>

                    <div className={"d-flex justify-content-between flex-column flex-md-row align-items-center"}>
                        <div className={"col-lg-2 col-md-6 col-sm-12"}>
                            <PitchControl showHeader={false} playerState={playerState} proxy={proxy}/>
                        </div>
                        <h3 className={"ml-5 mt-1 mb-1 d-none d-lg-block"}>{mediaName}</h3>
                        <div className={"col-lg-2 col-md-6 col-sm-12"}>
                            <Zoom showHeader={false} playerState={playerState} proxy={proxy}/>
                        </div>
                    </div>
                </div>

                <div id={"waveform"} className="w-100"/>
                <div id={"tl"} className="w-100"/>
                <div id={"mm"} className="w-100"/>
                <div style={{"height": "10px"}}></div>
            </div>
            }
            {playerType === "youtube" && <div>

                <div className={"d-flex flex-row justify-content-lg-between justify-content-md-center align-items-lg-end"}>
                    <div className={"col-lg-2 col-md-6 d-none d-lg-block"}>
                        <PitchControl showHeader={false} playerState={playerState} proxy={proxy}/>
                    </div>
                    <div id="ytplayer" ></div>
                    <div className={"col-lg-2 col-md-6 d-none d-lg-block"}>
                        <Zoom showHeader={false} playerState={playerState} proxy={proxy}/>
                    </div>
                </div>

                <div className={"d-flex flex-row justify-content-between d-lg-none bg-light"}>
                    <div className={"col-6"}>
                        <PitchControl showHeader={false} playerState={playerState} proxy={proxy}/>
                    </div>
                    <div className={"col-6"}>
                        <Zoom showHeader={false} playerState={playerState} proxy={proxy}/>
                    </div>
                </div>

                <div id={"waveform"} className={""}></div>
                <div id={"tl"}></div>
            </div>
            }


            <div className={"d-block d-md-none" + (playerType === "youtube" ? "mt-2" : "")} >
                {/*<p>sm</p>*/}
                <div className={"d-flex flex-column"}>
                    <div className={"d-flex flex-row align-items-start justify-content-between"}>
                        <div className={"d-flex flex-column col-12"}>
                            <div className={"d-flex justify-content-start align-items-center"}>
                                <div className={""}>
                                    <Playback showHeader={false} playerState={playerState} proxy={proxy}/>
                                </div>
                                <div className={""}>
                                    <LoopControl showHeader={false} playerState={playerState} proxy={proxy}/>
                                </div>
                            </div>
                            <div className={""}>
                                <Speed showHeader={false} playerState={playerState} proxy={proxy}/>
                            </div>
                            <div className={"col-12"}>
                                <LoopShed showHeader={false} playerState={playerState} proxy={proxy}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"d-none d-md-block d-lg-none " + (playerType === "youtube" ? "mt-2" : "")}>
                {/*<p>md</p>*/}
                <div className={"d-flex flex-column"}>
                    <div className={"d-flex flex-row align-items-start justify-content-between"}>
                        <div className={"d-flex flex-column col-6"}>
                            <div className={"d-flex justify-content-start align-items-center ms-1"}>
                                <div className={""}>
                                    <Playback showHeader={false} playerState={playerState} proxy={proxy}/>
                                </div>
                                <div className={""}>
                                    <LoopControl showHeader={false} playerState={playerState} proxy={proxy}/>
                                </div>
                            </div>
                            <div className={""}>
                                <Speed showHeader={false} playerState={playerState} proxy={proxy}/>
                            </div>
                        </div>
                        <div className={"col-6"}>
                            <LoopShed showHeader={false} playerState={playerState} proxy={proxy}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"d-none d-lg-block" +  (playerType === "youtube" ? " mt-2" : "")}>
                {/*<p>lg</p>*/}
                <div className={"d-flex flex-column"}>
                    <div className={"d-flex flex-row align-items-start justify-content-between"}>
                        <div className={"col-2"}>
                            <Speed showHeader={false} playerState={playerState} proxy={proxy}/>
                        </div>
                        <div className={"col-6 d-flex justify-content-end"}>
                        <div className={"me-2"}>
                            <Playback showHeader={false} playerState={playerState} proxy={proxy}/>
                        </div>
                        <div className={""}>
                            <LoopControl showHeader={false} playerState={playerState} proxy={proxy}/>
                        </div>
                        </div>
                        <div className={"col-4"}>
                            <LoopShed showHeader={false} playerState={playerState} proxy={proxy}/>
                        </div>
                    </div>
                </div>
                <div style={{"height": "100px"}}>
                </div>
            </div>
            {/*<Container fluid className={"mt-2"}>*/
            }
            {/*    /!*<p>lg</p>*!/*/
            }
            {/*    <Row fluid>*/
            }
            {/*        <Col lg={2} md={2} sm={2}>*/
            }
            {/*            <div style={{width:"100px"}}>*/
            }
            {/*            <Playback showHeader={false} playerState={playerState} proxy={proxy}/>*/
            }
            {/*            </div>*/
            }
            {/*        </Col>*/
            }
            {/*        <Col sm={6} md={{ span: 8, order: 3 }} lg={{order:2}} >*/
            }
            {/*            <LoopControl showHeader={false} playerState={playerState} proxy={proxy}/>*/
            }
            {/*        </Col>*/
            }
            {/*        <Col md={{ span: 8, order: 2 }} lg={{order:3, span: 2}}>*/
            }
            {/*            <Speed showHeader={false} playerState={playerState} proxy={proxy}/>*/
            }
            {/*        </Col>*/
            }
            {/*    </Row>*/
            }
            {/*</Container>*/
            }


            {/*<div className={"w-100 ps-1 " + (playerType === "youtube" ? "" : "")}>*/}
            {/*    <div className={"d-flex flex-column"}>*/}
            {/*        <div*/}
            {/*            className={"d-flex flex-md-row flex-wrap flex-column align-items-start justify-content-between"}>*/}
            {/*            <div className={"col-1 mt-4"}>*/}
            {/*                <div className={"mt-2"}>*/}
            {/*                    <Playback showHeader={false} playerState={playerState} proxy={proxy}/>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className={"col-md-8 col-sm-12 d-flex flex-column align-items-center"}>*/}
            {/*                <div className={""}>*/}
            {/*                    <LoopControl showHeader={true} playerState={playerState} proxy={proxy}/>*/}
            {/*                </div>*/}

            {/*            </div>*/}
            {/*            <div className={"col-lg-2"}>*/}
            {/*                <div className={"mt-2"}>*/}
            {/*                    <Speed showHeader={false} playerState={playerState} proxy={proxy}/>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className={"mt-4 col-12 d-flex flex-column align-items-center justify-content-center"}>*/}
            {/*            <LoopShed showHeader={true} playerState={playerState} proxy={proxy}/>*/}
            {/*        </div>*/}
            {/*        <div style={{"height": "100px"}}></div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default LocalPlayer;
