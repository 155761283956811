import {Button, Navbar} from "react-bootstrap";
import React from "react";

export const Navigation = ({loadMediaCallback, disableLoaderButton}) => {
    return <Navbar bg="light" expand="sm" expanded={false} >
        <Navbar.Brand>Loop Wizard
            <svg fill="#000000" width="25px" height="25px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M496 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h480c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zm-304-64l-64-32 64-32 32-64 32 64 64 32-64 32-16 32h208l-86.41-201.63a63.955 63.955 0 0 1-1.89-45.45L416 0 228.42 107.19a127.989 127.989 0 0 0-53.46 59.15L64 416h144l-16-32zm64-224l16-32 16 32 32 16-32 16-16 32-16-32-32-16 32-16z"></path></g></svg>
        </Navbar.Brand>
        {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />*/}
        {/*<Navbar.Collapse id="basic-navbar-nav" className={"collapse"}>*/}
            <Navbar.Text href="">
                <Button variant={"secondary"} onClick={loadMediaCallback} disabled={disableLoaderButton}>Load Media</Button>
            </Navbar.Text>
            {/*<Navbar.Text>*/}
            {/*    <Button  variant={"light"}>*/}
            {/*       <i className="bi bi-question-circle"></i>*/}
            {/*    </Button>*/}
            {/*</Navbar.Text>*/}
        {/*</Navbar.Collapse>*/}
    </Navbar>
}