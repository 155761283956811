import Waveform from "./wavesurver";
import Player from "./Player.ts";

function NewYTPlayer(videoId, readyCallback, syncCallback) {
    return new window.YT.Player(
        'ytplayer',
        {
            height: '315px',
            // width: '100%',
            videoId: videoId,
            playerVars: {
                'autoplay': 0,
                'controls': 0,
                'disablekb': 1,
                'rel': 0,
                'fs': 1,
                'enablejsapi': 1,
                'origin': window.location.origin,
                'playsinline': 1,
                //         'html5': 1,
                //        controls: 0,
                //         modestbranding: 1,
                //         showinfo: 0,
                //         rel: 0
            },
            events: {
                'onReady': readyCallback,
                'onStateChange': syncCallback
            }
        }
    );
}

export class YoutubePlayer extends Player {
    yt;
    audioCtx;

    const
    YT_UNSTARTED = -1;
    YT_ENDED = 0;
    YT_PLAYING = 1;
    YT_PAUSED = 2;
    YT_BUFFERING = 3;
    YT_CUED = 5;
    YT_STATES = {
        '-1': 'unstarted',
        '0': 'ended',
        '1': 'playing',
        '2': 'paused',
        '3': 'buffering',
        '5': 'cued'
    }

    playInitiated = null;
    pauseInitiated = null;
    playbackRates = null;
    currentPlaybackRateIndex = null;

    initWaveform(e, containerId) {
        this.playerState.setPlaybackRates(this.yt.getAvailablePlaybackRates());
        let pbRate = this.yt.getPlaybackRate();
        for(let i = 0; i < this.playerState.playbackRates.length; i++) {
            if (Math.abs(pbRate - this.playerState.playbackRates[i]) < .001) {
                this.currentPlaybackRateIndex = i;
                break;
            }
        }
        let duration = e.target.getDuration()
        let sampleRate = 8000; //minimum sample rate according to https://developer.mozilla.org/en-US/docs/Web/API/BaseAudioContext/createBuffer
        let numOfSamples = duration * sampleRate;
        let context = new (window.AudioContext || window.webkitAudioContext)();
        let audioBuffer = context.createBuffer(1, numOfSamples, sampleRate);
        let dataBuffer = audioBuffer.getChannelData(0);
        let smudge = Math.floor(sampleRate / 8);
        for (let i = 0; i < numOfSamples; i += 1) {
            if (i % (sampleRate * 5) - smudge <= 0)  {
                dataBuffer[i] = .9;
            } else if (i % (sampleRate  ) - smudge <= 0) {
                dataBuffer[i] = .4;
            } else {
                dataBuffer[i] = .05;
            }
        }

        this.audioCtx = context;

        this.waveform = Waveform(containerId, 75)
        this.registerEvents();
        //this.waveform.load("audioBuffer", peaks, false, duration );

        this.waveform.loadDecodedBuffer(audioBuffer);
    };

    togglePlay() {
        if(this.playerState.playing) {
            this.pause()
        } else {
            this.play()
        }
    }

    play() {
        this.yt.playVideo();
    }

    pause() {
        this.yt.pauseVideo();
    }

    syncYTToPlayer(e) {
        let playerState = e.target.getPlayerState()
        switch (playerState) {
            case this.YT_UNSTARTED:
            case this.YT_ENDED:
            case this.YT_PAUSED:
                // if player is going stop it
                if (this.playerState.playing === true) {
                    super.pause();
                }
                break;
            case this.YT_PLAYING:
                //when pausing, the player will emit a playing state before it pauses
                if (this.playerState.playing === false) {
                    super.play();
                } else {
                    setTimeout(() => this.syncYTToPlayer(e), 10)
                }
                break;
            case this.YT_BUFFERING:
                setTimeout(() => this.syncYTToPlayer(e), 10)
                break;
        }
        //this.setTime(e.target.getCurrentTime())

    }


    constructor(containerId, videoId, playerStateCallback) {
        super("youtubePlayer", playerStateCallback)
        let ytApiScript = document.getElementById('youtubeAPIScript');
        if (ytApiScript != null) {
            this.yt = NewYTPlayer(videoId,
                (e) => {
                    this.initWaveform(e, containerId)
                },
                (e) => this.syncYTToPlayer(e)
            );
        } else {
            //load the youtube api if it's not already loaded
            let tag = document.createElement('script');
            tag.setAttribute("id", "youtubeAPIScript");
            tag.src = window.location.protocol + "//www.youtube.com/iframe_api";
            let firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            window.onYouTubeIframeAPIReady = () => {
                this.yt = NewYTPlayer(videoId,
                    (e) => this.initWaveform(e, containerId),
                    (e) => this.syncYTToPlayer(e)
                );
            }
        }
    }

    onWaveFormReady() {
        this.waveform.setVolume(0);
        super.onWaveFormReady();
    }

    onPlay() {
        super.onPlay();
        this.sync()
    }

    onPause() {
        super.onPause();
        this.sync();
    }

    onRegionCreated(region) {
        super.onRegionCreated(region);
        this.sync()
    }

    onRegionUpdated(region) {
        super.onRegionUpdated(region);
        this.sync()
    }

    onSeek() {
        super.onSeek();
        this.sync()
    }

    onRegionIn() {
        super.onRegionIn();
        this.sync()
    }

    sync() {
        this.yt.seekTo(this.waveform.getCurrentTime())
    }

    setSpeed(value) {
        let speed = value;
        this.yt.setPlaybackRate(speed);
        this.waveform.setPlaybackRate(speed);
        this.playerState.setCurrentSpeed(speed);
        for(let i = 0; i < this.playerState.playbackRates.length; i++) {
            if (Math.abs(speed - this.playerState.playbackRates[i]) < .001) {
                this.currentPlaybackRateIndex = i;
                break;
            }
        }
    }

    destroy() {
        this.audioCtx && this.audioCtx.close();
        this.yt.destroy();
        super.destroy();
    }

    onRegionOut(region) {
        if(this.playerState.autoChangeSpeed) {
            if(this.loopIterations >= this.playerState.loopRepeats ) {
                let newIndex = this.currentPlaybackRateIndex < this.playerState.playbackRates.length - 1 ?
                    this.currentPlaybackRateIndex + 1 : this.playerState.playbackRates.length - 1;
                let newSpeed = this.playerState.playbackRates[newIndex]
                this.setSpeed(newSpeed);
                this.loopIterations = 0;
            }
        }
        return super.onRegionOut(region);
    }


}