import React, {useState, useEffect, PropsWithChildren} from "react";
import Button from "react-bootstrap/Button";
import {ButtonGroup, ButtonToolbar} from "react-bootstrap";
import PlayerState from "../../lib/PlayerState.js";
import Modal from "react-bootstrap/Modal";
import Player from "../../lib/Player";

export type PlaybackProps = {
    playerState: PlayerState | null | undefined;
    proxy: any;
    showHeader: boolean;
};

export const Playback = ({
                             playerState,
                             proxy,
                             showHeader = false,
                         }: PlaybackProps) => {


    return <div className={"d-flex flex-column "}>
        {showHeader && <div className={"d-flex flex-row align-items-center justify-content-center"}>
            <h5 className={"m-0 mb-1"}>Play</h5>
        </div>}
        <Button variant={"primary"}
                className={"rounded"}
                onClick={() => proxy("togglePlay")}>
            {!playerState.playing ?
                <i className="bi bi-play-fill"></i> :
                <i className="bi bi-pause-fill"></i>
            }
        </Button>
    </div>


};
