import Toast from "react-bootstrap/Toast";
import {useEffect, useState} from "react";
import CookieConsent, {Cookies} from "react-cookie-consent";
import {Button} from "react-bootstrap";


export const Consent = () => {
    const [consentCookie, setConsentCookie] = useState(Cookies.get("consent-cookie") !== undefined ? Cookies.get("consent-cookie") : null)

    const [showConsentBanner, setShowConsentBanner] = useState(consentCookie === null ? "visible" : "hidden")

    useEffect(() => {
        if (consentCookie === "true") {
            window.gtag('js', new Date());
            window.gtag('config', 'G-BKN3L77HY9');
            window.gConsentGranted();
        } else if (consentCookie === "false") {
            window.gConsentDenied()
        }
        return () => {}
    }, [consentCookie])

    return <div>
        <CookieConsent
            location="bottom"
            buttonText="I accept"
            cookieName="consent-cookie"
            enableDeclineButton
            declineButtonText="I decline"
            flipButtons={true}
            style={{background: "#2B373B"}}
            buttonStyle={{color: "#4e503b", fontSize: "13px"}}
            expires={150}
            visible={showConsentBanner}
            onAccept={() => {
                setShowConsentBanner("hidden")
                setConsentCookie(Cookies.get("consent-cookie"))
            }}
            onDecline={() => {
                setShowConsentBanner("hidden")
                setConsentCookie(Cookies.get("consent-cookie"))
            }}
        >
            This website uses cookies for analytics and for advertisement personalization.
        </CookieConsent>
        <div style={{"position": "fixed", "bottom": 0, "right": 0}} hidden={showConsentBanner === "visible"}>
            <Button size={"sm"} onClick={() => setShowConsentBanner("visible")}>
                <i className={"bi bi-cookie"}>
                    &nbsp; cookie consent
                </i>
            </Button>
        </div>
    </div>


}