import React, {useState, useEffect, PropsWithChildren} from "react";
import Button from "react-bootstrap/Button";
import {ButtonGroup, ButtonToolbar} from "react-bootstrap";
import PlayerState from "../../lib/PlayerState.js";
import Modal from "react-bootstrap/Modal";
import Player from "../../lib/Player";

export type PlaybackProps = {
    playerState: PlayerState | null | undefined;
    proxy;
    showHeader: boolean;
};

export const LoopControl = ({
                                    playerState,
                                    proxy,
                                    showHeader = true,
                                }: PlaybackProps) => {


    const keyboardControls = (e) => {
        let key = e.key.toUpperCase()
        switch (key) {
            case "A":
                proxy("setA");
                break;
            case "B":
                if (playerState.a !== null) {
                    proxy("setB");
                }
                break;
            case " ":
                proxy("togglePlay");
                break;
            case "L":
                proxy("toggleLoop");
                break;
            case "D":
                playerState.activeRegion && proxy("shrinkStretchActiveRegion", 2);
                break;
            case "H":
                playerState.activeRegion && proxy("shrinkStretchActiveRegion", .5);
                break;
            case "ARROWLEFT":
                playerState.activeRegion && proxy("moveActiveRegion", -1);
                break;
            case "ARROWRIGHT":
                playerState.activeRegion && proxy("moveActiveRegion", 1);
                break
            default:
                break;
        }
    }

    useEffect(() => {
        window.addEventListener("keyup", keyboardControls);
        return () => {
            window.removeEventListener("keyup", keyboardControls);
        };
    }, [keyboardControls, playerState]);



    return <div className={"d-flex flex-column align-items-center justify-content-center"}>
        {showHeader && <div className={"d-flex flex-row align-items-center justify-content-center"}>
            <h5 className={"h-100 m-0"}>Loop Control</h5>
            <Instructions/>
        </div>}
        <ButtonToolbar>
            <ButtonGroup size={"sm"}>
                <Button variant={"outline-dark"}
                        className={"rounded-left"}
                        onClick={() => proxy("moveActiveRegion",-1)}
                        disabled={!playerState.activeRegion}
                >
                    <i className="bi bi-rewind"></i>
                </Button>
                <Button variant={"outline-dark"}
                        onClick={() => proxy("shrinkStretchActiveRegion", .5)}
                        disabled={!playerState.activeRegion}
                >.5X
                </Button>

                <Button variant={"outline-dark"} size={"sm"}
                        onClick={() => proxy("setA")}
                >
                    A
                </Button>
                <Button variant={"outline-dark"}
                        onClick={() => proxy("setB")}
                        disabled={playerState.a === null}
                >B
                </Button>
                <Button variant={"outline-dark"}
                        onClick={() => proxy("shrinkStretchActiveRegion", 2)}
                        disabled={!playerState.activeRegion}
                >2X
                </Button>
                <Button variant={"outline-dark"}
                        onClick={() => proxy("moveActiveRegion", 1)}
                        disabled={!playerState.activeRegion}
                >
                    <i className="bi bi-fast-forward"></i>
                </Button>
                <Button variant={"outline-dark"}
                        className={"rounded-right"}
                        onClick={() => proxy("toggleLoop")}
                        disabled={!playerState.activeRegion}
                >
                    {!playerState.looping ?
                        <i className="bi bi-arrow-repeat"></i> :
                        <i className="bi bi-x-circle"></i>}

                </Button>
                {!showHeader && <Instructions/>}
            </ButtonGroup>

        </ButtonToolbar>

    </div>

};

const Instructions = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant={"light"} size={"sm"} onClick={handleShow}>
                <i className="bi bi-question-circle"></i>
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Playback Controls</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Looping Audio</h4>
                    <p>Click and drag on the timeline to loop audio or use the "A" and "B" button to set loops while
                        media is playing. Use the loop button to toggle looping.</p>
                    <p>Use the "forward" and "back" buttons to slide active loop, and use the ".5x" and "2x" buttons
                        to shrink and stretch the loop.</p>
                    <h4>Keyboard Shortcuts</h4>
                    <ul className={"text-left"}>
                        <li>"Space" to play/pause</li>
                        <li>"A" to set the start of the loop</li>
                        <li>"B" to set the end of the loop</li>
                        <li>"H" to half the length of a loop</li>
                        <li>"D" to double the length of a loop</li>
                        <li>"L" to toggle loop on and off</li>
                        <li>"Arrow left" to move the loop left</li>
                        <li>"Arrow right" to move the loop right</li>
                    </ul>
                </Modal.Body>
            </Modal>
        </>
    );
}
