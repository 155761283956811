import WaveSurfer from "wavesurfer.js/dist/wavesurfer";
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions";
import MinimapPlugin from "wavesurfer.js/dist/plugin/wavesurfer.minimap";
import tl from "./timeline"
//import AudioBuffer from "audio-buffer"
import CursorPlugin from "wavesurfer.js/dist/plugin/wavesurfer.cursor";
//import from "wavesurfer.js/dist/plugin/"
import PlayheadPlugin from "wavesurfer.js/dist/plugin/wavesurfer.playhead";
//import MarkersPlugin from "wavesurfer.js/src/plugin/markers";
import MarkersPlugin from "wavesurfer.js/dist/plugin/wavesurfer.markers";

const region = RegionsPlugin.create({
    // id: "loop-area",
    //handleStyle: {width: "1px"},
    maxRegions: 2,
    dragSelection: true,
    slop: 2,
    resize: true,
    color: "rgb(245,161,152,.4)",
    vertical: false,
    loop: true
})

const mm = MinimapPlugin.create({
    container: '#mm',
    waveColor: '#777',
    progressColor: '#222',
    height: 50
})


const cursor = CursorPlugin.create({
    showTime: true,
    opacity: 1,
    customShowTimeStyle: {
        'background-color': '#000',
        color: '#fff',
        padding: '2px',
        'font-size': '10px'
    }
})

const playhead = PlayheadPlugin.create({
    //returnOnPause: true,
    moveOnSeek: true,
    draw: true
})

// @todo leave out markers for now
const markers = MarkersPlugin.create({
    markers: []
})
//wavesurfer.loadDecodedBuffer(audioBuffer);

const init = (containerId, height) => {
    return WaveSurfer.create({
        //audioContext: audioCtx,
        backend: "WebAudio",
        container: containerId,
        waveColor: '#08e3ec',
        progressColor: '#035663',
        backgroundColor: "rgba(191,191,191,0.4)",
        mediaControls: true,
        scrollParent: false,
        height: height,
        //responsive: true,
        plugins: [
            region,
            //mm,
            tl,
            cursor,
            playhead,
            markers
        ],
        audioRate: 1,
        zoom: 50

    });
}
export default init;