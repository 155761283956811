import React, {useState} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import {InputGroup} from "react-bootstrap";

export const Speed = ({playerState, proxy, showHeader}) => {

    // in the ui we deal with speed as a percentage
    // in the player we deal with speed as a float with normal speed being 1
    let minSpeed = playerState.playbackRates[0] * 100;
    let maxSpeed = 200;

    const slideCallback = (e) => {
        let speed = e.target.value / 100
        if (playerState.playerType === "youtubePlayer") {
            let closest = 0;
            let greatestDiff = 999999;
            for (let i = 0; i < playerState.playbackRates.length; i++) {
                let diff = Math.abs(speed - playerState.playbackRates[i])
                if (diff < greatestDiff) {
                    closest = i;
                    greatestDiff = diff;
                }
            }
            speed = playerState.playbackRates[closest]
        }
        proxy("setSpeed", speed)
    }

    const setSpeedCallback = (val) => {
        let speed = (val/100)
        proxy("setSpeed", speed)
    }

    const incrementSpeedCallback = (val) => {
        let newSpeed = playerState.currentSpeed * 100  + val
        if (newSpeed < minSpeed) {
            newSpeed = minSpeed
        }
        if (newSpeed > maxSpeed) {
            newSpeed = maxSpeed
        }
        proxy("setSpeed", (newSpeed/100))
    }

    let buttons = []
    let datalist = []

    playerState.playbackRates.forEach(function (rate, index) {
        minSpeed = Math.min(rate * 100, minSpeed)
        maxSpeed = Math.max(rate * 100, maxSpeed)
        buttons.push(<button className="btn btn-secondary btn-sm m-1" onClick={() => setSpeedCallback(rate * 100)}
                             key={"ok-" + index}>{rate * 100}%</button>)
        datalist.push(<option key={"ok-" + index}>{rate * 100}</option>)
    });


    return <div className={"border p-1"}>
        {showHeader && <div className={"d-flex flex-row align-items-center justify-content-center"}>
            <h5 className={"h-100 m-0"}>Speed Control</h5>
            <Instructions/>
        </div>}
        <InputGroup>
            <InputGroup.Text className={"p-2 fs-6"} id="b">Speed</InputGroup.Text>
            <Form.Control
                variant={"outline-dark"}
                type="number"
                aria-describedby="b"
                value={Math.round(playerState.currentSpeed * 100)}
                className={"p-2"}
                //style={{width: "50px"}}
                onChange={slideCallback}
            />
            <InputGroup.Text className={"p-2 fs-6"}>%</InputGroup.Text>
            <Form.Range min={minSpeed}
                        max={maxSpeed}
                        value={Math.round(playerState.currentSpeed * 100)}
                        onChange={slideCallback} list={"playback-rates"}/>

        </InputGroup>

        <div className={"d-flex justify-content-center border mt-1"}>
            <ButtonGroup  size={"sm"}>
                <Button variant={"secondary"}
                        onClick={() => {
                            incrementSpeedCallback(-25)
                        }}>
                    <i className={"bi bi-dash"}></i>
                </Button>

                <Button variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                            incrementSpeedCallback(25)
                        }}>
                    <i className={"bi bi-plus"}></i>
                </Button>
                <Button variant={"secondary"} size={"sm"} onClick={() => {
                    setSpeedCallback(100)
                }}>
                    <i className="bi bi-house"></i>
                </Button>
                <Dropdown>
                    <Dropdown.Toggle variant="secondary" size={"sm"} id="dropdown-basic">
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {buttons}
                    </Dropdown.Menu>
                </Dropdown>
                <Instructions/>
            </ButtonGroup>
        </div>

        <datalist id={"playback-rates"}>
            {datalist}
        </datalist>
    </div>

}

const Instructions = (title, text) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant={"secondary"} size="sm" onClick={handleShow} style={{background: "None"}}>
                <i className="bi bi-question-circle"></i>
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Changing Speed</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Use the speed slider to change speed.</p>
                    <p>Speeds in the youtube player are constrained to the speeds youtube supports.</p>
                </Modal.Body>
            </Modal>
        </>
    );
}