import React from "react";
import {Button, Form} from "react-bootstrap";

export const FileUploader = ({callback}) => {
    const onChange = ({
                          target: {
                              files: [file],
                              value
                          }
                      }) => {
        const fileTest = /(.mp3|.ogg|.wav|.m4a)$/i.test(value);
        if (fileTest) {
            callback(window.URL.createObjectURL(file), file.name)
        } else {
            window.alert("unsupported file type, please upload an .mp3, .ogg or .wav file");
        }
    };
    const loadDemo = () => {
        callback("jazzblueslibofcongress.mp3")
    };
    return <Form>
        <div className="form-group">
            <span>Upload a local media file: </span>
            <input type="file" className="form-control-file"  onChange={onChange}/>
            {/*<h5 className={"mt-1"}>Or</h5>*/}
            <Button variant={"outline-secondary"} onClick={loadDemo} className={"mt-2"}>Load Demo Track</Button>
        </div>
    </Form>
}

export const YoutubeUploader = ({callback}) => {
    function getID(url) {
        url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
    }

    const submit = (e) => {
        e.preventDefault();
        let v = document.getElementById("youtubelink").value || document.getElementById("youtubelink").placeholder;

        let split = v.split('v=')
        if (split.length === 1) {
            callback(split[0])
        }
        v = getID(v)
        callback(v)
    }
    return <Form >
        <div className="form-group">
            <span>Enter a youtube link or Video ID:</span>
            <input type="text" className="form-control" id="youtubelink" aria-describedby="emailHelp"
                   placeholder="https://www.youtube.com/watch?v=30FTr6G53VU" />
        </div>
        <button type="submit" className="d-inline-flex btn btn-primary" onClick={submit}>Submit</button>
    </Form>

}


export const Uploader = ({fileCallback, youtubeCallback, closeCallback}) => {
    return <div className={"container mt-3 mb-3"}>
        <div className={"col-10 p-1 border border-secondary"}
             style={{
                 "backgroundColor": "#f7f7f9"
             }}>
            <div role="button" className={"col-1 float-end m-1"} onClick={closeCallback}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                     className="bi bi-x-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>

            <h1 className={"m-1"}> Upload media: </h1>

            <div className={"row m-5 d-flex"}>
                <div className={"col-lg-4 border-secondary pt-2"}>
                    <FileUploader callback={fileCallback}/>
                </div>
                <div className={"col-lg-2 text-center"}>
                    {/*<h2>OR</h2>*/}
                </div>
                <div className={"col-lg-6 border-secondary p-2"}>
                    <YoutubeUploader callback={youtubeCallback}/>
                </div>
            </div>
            {/*</div>*/}
        </div>
    </div>
}
