import {ButtonGroup, Button, InputGroup, Form, Tooltip, Overlay} from "react-bootstrap";
import React, {useState, useRef} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";


export const PitchControl = ({playerState, proxy, showHeader}) => {

    let semi = playerState.currentPitch;
    let disabled = playerState.playerType !== "localPlayer";

    const update = (e) => {
        let val = Math.min(12, e.target.value)
        val = Math.max(-12, val)
        proxy('setPitch', val)
    }
    const setPitch = (value) => {
        if (value < -12) value = -12
        if (value > 12) value = 12
        !disabled && proxy('setPitch', value)
    };


    const [show, setShow] = useState(false);
    const target = useRef(null);

    return <div ref={target}
                onMouseOver={() => setShow(true)}
                onMouseOut={() => setShow(false)}
                role={"button"}
                className={"border p-1"}
    >
        {disabled && <Overlay target={target.current} show={show} placement="top">
            {(props) => (
                <Tooltip id="overlay-example" {...props}>
                    Disabled for youtube player, load media directly to use this feature.
                </Tooltip>
            )}
        </Overlay>}
        {showHeader && <div className={"d-flex flex-row align-items-center justify-content-center"}>
            <h5 className={"h-100 m-0"}>Pitch Control</h5>
            <Instructions/>
        </div>}
        <InputGroup>
            <InputGroup.Text className={"p-2 fs-6"} id="b">Pitch</InputGroup.Text>
            <Form.Control
                id="pitchslider"
                type="number"
                value={semi}
                onChange={update}
                disabled={disabled}
                className={"ml-1"}
            />
            <InputGroup.Text className={"p-2 fs-6"} id="b">Steps</InputGroup.Text>
            {/*<label htmlFor="pitch">pitch:</label>*/}
            {/*<input id="pitchslider" type="number" value={semi} onChange={update} disabled={disablePitch} style={{width: "50px"}}*/}
            {/*       className={"ml-1"}/>*/}
            {/*&nbsp;steps*/}
            <Form.Range id="pitch" type="range" min="-12" max="12" value={semi}
                        onChange={update} disabled={disabled}/>

        </InputGroup>

        <div className="d-flex align-content-center justify-content-center border mt-1">
            <ButtonGroup size={"sm"} className={"p-0 m-0"}>
                <Button variant={"secondary"}
                        disabled={disabled}
                        size={"sm"}
                        onClick={() => {
                            setPitch(semi - 1)
                        }}
                >
                   <i className={"bi bi-dash"}></i>
                </Button>
                <Button variant={"secondary"} disabled={disabled} size={"sm"} onClick={() => {
                    setPitch(semi + 1)
                }}>
                    <i className={"bi bi-plus"}></i>
                </Button>
                <Button variant={"secondary"} disabled={disabled} size={"sm"} onClick={() => {
                    setPitch(0)
                }}>
                    <i className="bi bi-house"></i>
                </Button>
                <Dropdown>
                    <Dropdown.Toggle variant="secondary" disabled={disabled} size={"sm"} id="dropdown-basic">
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Button variant={"secondary"} size={"sm"} onClick={() => setPitch(-5)}><i
                            className="bi bi-arrow-down"></i>4th</Button>
                        <Button variant={"secondary"} size={"sm"} onClick={() => setPitch(5)}><i
                            className="bi bi-arrow-up"></i>4th</Button>
                        <Button variant={"secondary"} size={"sm"} onClick={() => setPitch(-7)}><i
                            className="bi bi-arrow-down"></i>5th</Button>
                        <Button variant={"secondary"} size={"sm"} onClick={() => setPitch(7)}><i
                            className="bi bi-arrow-up"></i>5th</Button>
                        <Button variant={"secondary"} size={"sm"} onClick={() => setPitch(-12)}><i
                            className="bi bi-arrow-down"></i>oct</Button>
                        <Button variant={"secondary"} size={"sm"} onClick={() => setPitch(12)}><i
                            className="bi bi-arrow-up"></i>oct</Button>
                    </Dropdown.Menu>
                </Dropdown>
                <Instructions/>
            </ButtonGroup>
        </div>
    </div>
}

const Instructions = (title, text) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant={"secondary"} size={"sm"} onClick={handleShow}>
                <i className="bi bi-question-circle"></i>
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Changing Pitch</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   <p>Use the pitch slider to transpose. This feature is disabled for youtube player.
                   </p>
                </Modal.Body>
            </Modal>
        </>
    );
}