import Waveform from "./wavesurver.js";
// @ts-ignore
import PlayerState from "./PlayerState.ts";


export default class Player {
    waveform;
    playerState: PlayerState;

    markers = {
        a: null,
        b: null,
        rA: null,
        rB: null
    }
    loopIterations = 0;
    autoExpandDuration = 0;

    constructor(type:string, playerStateCallback: (event: string, stateValue: PlayerState) => void) {
        this.playerState = new PlayerState(type, playerStateCallback);
    }

    initWaveform(containerId, fileUrl) {
        this.waveform = Waveform(containerId, 128)
        this.registerEvents();
        this.waveform.load(fileUrl);
    }

    onWaveFormReady() {
        this.markers.a = {
            time: 0,
            label: "A",
            color: '#26ff00',
            //position: 'top'
            draggable: true
        }
        this.waveform.addMarker(this.markers.a)
        this.playerState.setA(0);
    }



    onRegionUpdated(region) {
        this.updateRegions(region)
    }

    onRegionCreated(region) {
        this.updateRegions(region)
    }

    onRegionIn() {
    };

    onRegionOut(region) {
        this.loopIterations++;
        if(this.playerState.autoMoveLoop) {
            if(this.loopIterations >= this.playerState.loopRepeats ) {
                this.moveActiveRegion(1);
                this.loopIterations = 0;
                return;
            }
        } else if(this.playerState.autoExpandLoop) {
            if(this.loopIterations >= this.playerState.loopRepeats) {
                this.stretchActiveRegionByDuration(this.autoExpandDuration);
                this.loopIterations = 0;
                return;
            }
        }
        region.playLoop();
    };

    onPlay() {
        if(this.playerState.playing){
            return;
        }
        this.playerState.setPlaying(true);
        this.loopIterations = 0;
        this.autoExpandDuration = this.getActiveRegionDuration();
    }

    onPause() {
        this.playerState.setPlaying(false);
    }

    onSeek() {};

    onResize() {
        this.redraw();
    };

    setLooping(l) {
        this.playerState.setLooping(l);
    }

    registerEvents = () => {
        this.waveform.on("ready", () => this.onWaveFormReady())
        this.waveform.on("region-created", (region) => this.onRegionCreated(region))
        this.waveform.on("region-updated", (region) => this.onRegionUpdated(region))
        this.waveform.on("play", () => this.onPlay())
        this.waveform.on("pause", () => this.onPause())
        this.waveform.on("seek", () => this.onSeek())
        this.waveform.on("region-in", () => this.onRegionIn())
        this.waveform.on("region-out", (region) => this.onRegionOut(region))
        // this.waveform.on("marker-click", (e) => {
        //     console.log("click", e)
        // })
        // this.waveform.on("marker-drag", (e) => {
        //     console.log("drag", e)
        // })
        // this.waveform.on("marker-drop", (e) => {
        //     console.log("drop", e)
        // })
    }

    destroy() {
        window.removeEventListener("resize", this.onResize)
        this.waveform.destroy()
    }

    /**
     * Returns the new play state, true for playing false for paused
     * @returns {boolean}
     */
    togglePlay() {
        this.waveform.playPause();
    }

    play() {
        this.waveform.play();
    }

    pause() {
        this.waveform.pause();
    }


    setZoom(value) {
        this.waveform.zoom(value);
        this.playerState.setCurrentZoom(value);
    }

    updateRegions(region) {
        this.waveform.seekTo(region.start / this.waveform.getDuration());
        this.removeExtraRegions(region);
        this.updateRegionAB(region);
        this.playerState.setActiveRegion(region);
    }

    updateRegionAB(region) {
        let A = region.start;
        let B = region.end;

        this.playerState.setA(A);
        this.playerState.setB(B);

        this.markers.a = null
        this.markers.b = null
        this.markers.rA = {
            time: A,
            label: "A",
            color: '#1da3f5',
        }
        this.markers.rB = {
            time: B,
            label: "B",
            color: '#1da3f5',
        }
        this.rebuildMarkers();

    }

    rebuildMarkers() {
        this.waveform.clearMarkers();
        if (this.markers.a !== null) {
            this.waveform.addMarker(this.markers.a)
        }
        if(this.markers.b !== null) {
            this.waveform.addMarker(this.markers.b)
        }
        if(this.markers.rA !== null) {
            this.waveform.addMarker(this.markers.rA)
        }
        if(this.markers.rB !== null) {
            this.waveform.addMarker(this.markers.rB)
        }

    }

    removeExtraRegions(region) {
        let regions = region.wavesurfer.regions.list;
        let keys = Object.keys(regions);
        if (keys.length > 1) {
            regions[keys[0]].remove();
        }
    }

    redraw() {
        this.waveform.drawer.fireEvent('redraw');
        this.waveform.playhead._onResize()
        this.waveform.markers._updateMarkerPositions();
    }

    setLoopRepeats(repeats) {
        this.playerState.setLoopRepeats(repeats);
    }
    setAutoChangePitch(autoChangePitch: boolean) {
        this.playerState.setAutoChangePitch(autoChangePitch);
    }
    setAutoChangeSpeed(autoChangeSpeed: boolean) {
        this.playerState.setAutoChangeSpeed(autoChangeSpeed);
    }
    setAutoMoveLoop(autoMoveLoop: boolean) {
        this.playerState.setAutoMoveLoop(autoMoveLoop);
    }


    setAutoExpandLoop(autoExpandLoop: boolean) {
        this.playerState.setAutoExpandLoop(autoExpandLoop);
    }
    setAutoSpeedIncrease(autoSpeedIncrease: number) {
        this.playerState.setAutoSpeedIncrease(autoSpeedIncrease);
    }

    setAutoPitchShift(autoPitchShift: number) {
        this.playerState.setAutoPitchShift(autoPitchShift);
    }

    setA() {
        let time = this.waveform.getCurrentTime();
        this.waveform.playhead.setPlayheadTime(time);
        this.markers.a = {
            id: "A",
            time: time,
            label: "A",
            color: '#26ff00',
            //position: 'top'
            draggable: true

        }
        this.rebuildMarkers();
        this.playerState.setA(time);
    }

    setB() {
        if (this.playerState.a === null) {
            return
        }
        let time = this.waveform.getCurrentTime();

        this.playerState.setB(time);
        this.waveform.clearRegions();

        this.markers.b = {
            time: time,
            label: "B",
            color: '#26ff00',
            //position: 'top'
            draggable: true
        }
        this.rebuildMarkers();

        this.waveform.addRegion({
            "id": "ab",
            "start": this.playerState.a,
            "end": this.playerState.b,
            "loop": true,
            "color": "rgb(245,161,152,.4)"
        })

    }


    shrinkStretchActiveRegion(factor) {
        let activeRegion = this.playerState.activeRegion;
        let start = activeRegion.start;
        let end = activeRegion.end;
        let duration = end - start;
        let newDuration = duration * factor;
        this.waveform.clearRegions();
        let maxEnd = this.waveform.getDuration();
        if (start + newDuration > maxEnd) {
            end = maxEnd
        } else {
            end = start + newDuration
        }
        this.waveform.addRegion({
            "id": "ab",
            "start": start,
            "end": end,
            "loop": true
        })
    }

    getActiveRegionDuration() {
        if(this.playerState.activeRegion === null) {
            return 0;
        }
        let activeRegion = this.playerState.activeRegion;
        let start = activeRegion.start;
        let end = activeRegion.end;
        return end - start;
    }

    stretchActiveRegionByDuration(duration) {
        let activeRegion = this.playerState.activeRegion;
        let start = activeRegion.start;
        let end = activeRegion.end;
        let newDuration = duration + (end - start);
        this.waveform.clearRegions();
        let maxEnd = this.waveform.getDuration();
        if (start + newDuration > maxEnd) {
            end = maxEnd
        } else {
            end = start + newDuration
        }
        this.waveform.addRegion({
            "id": "ab",
            "start": start,
            "end": end,
            "loop": true
        })
    }

    toggleLoop() {
        if (this.playerState.activeRegion !== null && this.playerState.looping === true) {
            this.playerState.setLooping(false);
            this.waveform.clearRegions();
        } else if(this.playerState.activeRegion !== null && this.playerState.looping === false) {
            this.setLooping(true);
            this.waveform.addRegion(this.playerState.activeRegion)
        }
    }

    moveActiveRegion(loopDistance) {
        let activeRegion = this.playerState.activeRegion;
        let start = activeRegion.start;
        let end = activeRegion.end;
        let duration = end - start;
        start += loopDistance * duration;
        this.waveform.clearRegions();
        let minStart = 0;
        let maxStart = this.waveform.getDuration() - duration;
        if (start < minStart) {
            start = 0
        }
        if (start > maxStart) {
            start = maxStart
        }
        end = Math.min(start + duration, this.waveform.getDuration());
        this.waveform.addRegion({
            "id": "ab",
            "start": start,
            "end": end,
            "loop": true
        })
    }


}



