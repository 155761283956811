export default class PlayerState {

    playerType: string = null;
    playing: boolean = false;
    looping: boolean = false;
    activeRegion = null;
    a = null;
    b = null;

    autoMoveLoop: boolean = false;
    autoExpandLoop: boolean = false;
    autoChangePitch: boolean = false;
    autoChangeSpeed: boolean = false;
    loopRepeats: number = 2;
    autoPitchShift: number = 1;
    autoSpeedIncrease: number = .25;
    currentSpeed: number = 1.0;
    currentPitch: number = 0;
    currentZoom: number = 0;
    playbackRates = [.25, .50, .75, 1, 1.25, 1.5, 1.75, 2];

    setLoopRepeats(loopRepeats) {
        this.loopRepeats = loopRepeats;
        this.stateCallback(this);
    }

    setPlaying(playing) {
        this.playing = playing;
        this.stateCallback(this);
    }

    setCurrentZoom(currentZoom) {
        this.currentZoom = currentZoom;
        this.stateCallback(this);
    }

    setLooping(looping) {
        this.looping = looping
        this.stateCallback(this);
    };

    setA(a) {
        this.a = a;
        this.stateCallback(this);
    };

    disableAuto() {
        this.autoChangePitch = false;
        this.autoChangeSpeed = false;
        this.autoExpandLoop = false;
        this.autoMoveLoop = false
    }

    setAutoChangePitch(autoChangePitch: boolean) {
        this.disableAuto()
        this.autoChangePitch = autoChangePitch;
        this.stateCallback(this);
    }
    setAutoPitchShift(autoPitchShift: number) {
        this.autoPitchShift = autoPitchShift;
        this.stateCallback(this);
    }

    setAutoChangeSpeed(autoChangeSpeed: boolean) {
        this.disableAuto();
        this.autoChangeSpeed = autoChangeSpeed;
        this.stateCallback(this);
    }
    setAutoSpeedIncrease(autoSpeedIncrease: number) {
        this.autoSpeedIncrease = autoSpeedIncrease;
        this.stateCallback(this);
    }

    setAutoMoveLoop(autoMoveLoop: boolean) {
        this.disableAuto();
        this.autoMoveLoop = autoMoveLoop;
        this.stateCallback(this);
    }

    setAutoExpandLoop(autoExpandLoop: boolean) {
        this.disableAuto();
        this.autoExpandLoop = autoExpandLoop;
        this.stateCallback(this);
    }


    setB(b) {
        this.b = b;
        this.stateCallback(this);
    }

    setCurrentSpeed(currentSpeed) {
        this.currentSpeed = currentSpeed;
        this.stateCallback(this);
    }

    setCurrentPitch(currentPitch) {
        this.currentPitch = currentPitch;
        this.stateCallback(this);
    }

    setPlaybackRates(playbackRates) {
        this.playbackRates = playbackRates;
        this.stateCallback(this);
    }

    setActiveRegion(activeRegion) {
        if (!this.looping) {
            this.looping = true;
        }
        this.activeRegion = activeRegion;
        this.stateCallback(this);
    }

    stateCallback = (stateValue: PlayerState) => {
    }

    // used as a clone method for react to pickup changes to the object
    static fromPlayerState(playerState: PlayerState): PlayerState {
        let newState = new PlayerState(null, null);
        newState.playerType = playerState.playerType;
        newState.playing = playerState.playing;
        newState.looping = playerState.looping;
        newState.activeRegion = playerState.activeRegion;
        newState.a = playerState.a;
        newState.b = playerState.b;
        newState.autoMoveLoop = playerState.autoMoveLoop;
        newState.autoExpandLoop = playerState.autoExpandLoop;
        newState.loopRepeats = playerState.loopRepeats;
        newState.autoChangePitch = playerState.autoChangePitch;
        newState.autoChangeSpeed = playerState.autoChangeSpeed;
        newState.currentSpeed = playerState.currentSpeed;
        newState.currentPitch = playerState.currentPitch;
        newState.playbackRates = playerState.playbackRates;
        newState.currentZoom = playerState.currentZoom;
        newState.autoSpeedIncrease = playerState.autoSpeedIncrease;
        newState.autoPitchShift = playerState.autoPitchShift;
        return newState;
    }

    constructor(playerType: string, stateCallback: (stateValue: PlayerState) => void) {
        this.playerType = playerType;
        if (stateCallback !== undefined && stateCallback !== null) {
            this.stateCallback = stateCallback;
        }
        this.stateCallback(this)
    }
}
